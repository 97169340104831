import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitleService } from '@fmnts/common/browser';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsTextDirective } from '@fmnts/components/content';
import { FmntsEmptyStateModule } from '@fmnts/components/empty-state';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { I18nModule, TranslationService } from '@fmnts/i18n';
import { faLock } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'shared-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  standalone: true,
  imports: [
    FmntsButtonModule,
    FmntsTextDirective,
    FmntsEmptyStateModule,
    FmntsIconsModule,
    I18nModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent {
  /** @internal */
  protected readonly _iconUnauthorized = faLock;

  constructor(title: TitleService, translationService: TranslationService) {
    title.setTitle(translationService.getTranslation('unauthorized_title'));
  }
}
