import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppFeatureFlags } from '@app/core/config/feature-flag.service';
import { AuthStore } from '@formunauts/shared/auth/data-access';
import { Auth } from '@formunauts/shared/domain';

/**
 * Performs the redirect when accessing `<host>/`.
 *
 * @description
 * - If unauthenticated, it redirects to login-page
 * - Fundraiser users will be redirected to Next
 * - All other users will be redirected to the Cockpit
 */
export function rootRedirect(): CanActivateFn {
  return () => {
    const router = inject(Router);
    const featureFlags = inject(AppFeatureFlags);
    const authStore = inject(AuthStore);

    if (!authStore.isAuthenticated()) {
      return router.createUrlTree(['/auth', 'login']);
    }

    if (featureFlags.on('next') && authStore.hasRole(Auth.Role.Fundraiser)) {
      return router.createUrlTree(['/next']);
    }

    return router.createUrlTree(['/cockpit']);
  };
}
