import { DOCUMENT } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ROOT_EFFECTS, ROOT_REDUCERS, metaReducers } from '@app/+state';
import { provideFormDataTrash } from '@app/next/shared/data-access/form-data-trash';
import { TransformHttpErrorInterceptor } from '@app/shared/infra/transform-http-error.interceptor';
import { provideLoqateApiClient } from '@fmnts/api/vendors/loqate';
import { provideZendeskApiClient } from '@fmnts/api/vendors/zendesk';
import {
  FmntsBrowserModule,
  PlatformStyleService,
} from '@fmnts/common/browser';
import { FmntsDefaultIconsLib } from '@fmnts/components/icons';
import { SharedCustomerDataAccessModule } from '@fmnts/shared/customer/data-access';
import { provideGeolocation } from '@fmnts/shared/geolocation/data-access';
import {
  AuthInterceptor,
  SharedAuthDataAccessModule,
} from '@formunauts/shared/auth/data-access';
import {
  UpdateService,
  decodeAngularVersion,
  provideBundleDataAccess,
  withUpdateCheck,
} from '@formunauts/shared/bundle/data-access';
import { provideSharedDataAccess } from '@formunauts/shared/data-access';
import { provideRaiseNowClient } from '@formunauts/shared/vendors/raisenow/data-access';
import { SharedViewOptionsDataAccessModule } from '@formunauts/shared/view-options/data-access';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as S from 'effect/String';
import { first } from 'rxjs';
import { environment } from '../environments/environment';
import { AppLocalizationModule } from './app-l10n.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { provideAppLogging } from './core/app-logging';
import { ConfigService } from './core/config/config.service';
import { provideFmntsAppIndexedDb } from './core/db/app-db.module';
import { AppFormDataTrashIdbRepository } from './core/diagnostic/data-access';
import { provideAppSentry } from './core/error/app-sentry.config';
import { provideAppSupportRequest } from './core/support-request';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AppLocalizationModule,
    BrowserAnimationsModule,
    BrowserModule,
    FmntsBrowserModule,
    FmntsDefaultIconsLib,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    SharedModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        // TODO(ngrx): Use interfaces for handling failed HTTP API actions
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot(ROOT_EFFECTS),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot(),
    SharedAuthDataAccessModule,
    SharedCustomerDataAccessModule,
    SharedViewOptionsDataAccessModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfig],
      useFactory: (appConfig: AppConfig) => () => appConfig.init$(),
    },
    provideAppSentry(),
    {
      // Provide first, so that it doesn't interfer with AuthInterceptor
      provide: HTTP_INTERCEPTORS,
      useClass: TransformHttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideBundleDataAccess(
      {
        name: '@fmnts/app',
        version: decodeAngularVersion(environment.version),
      },
      withUpdateCheck({ interval: 4 * 60 * 60 * 1_000 }),
    ),
    provideRaiseNowClient({
      loadConfig: (
        { settings: { raiseNowTestMode: testMode } } = inject(ConfigService),
      ) => ({
        testMode: S.isNonEmpty(testMode) && testMode.toLowerCase() === 'true',
      }),
    }),
    provideZendeskApiClient({
      loadConfig: () => ({ accountUrl: environment.zendesk.accountUrl }),
    }),
    provideLoqateApiClient({
      loadConfig: ({ settings: { loqate } } = inject(ConfigService)) => ({
        addressCapture: {
          apiKey: loqate.addressCaptureApiKey,
        },
        bankValidation: {
          apiKey: loqate.bankValidationApiKey,
        },
      }),
    }),
    provideSharedDataAccess(),
    provideGeolocation(),
    provideFmntsAppIndexedDb(),
    provideAppLogging(),
    provideFormDataTrash({
      repository: () => inject(AppFormDataTrashIdbRepository),
      config: {
        maxEntries: 20,
      },
    }),
    provideAppSupportRequest(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private readonly _doc = inject(DOCUMENT);

  constructor() {
    this._eagerlyCreateInstances();
    this._setupPlatformStyles();
    this._setupPreconnectUrls();
  }

  /**
   * Only used to eagerly create instaces of DI tokens.
   */
  private _eagerlyCreateInstances(
    // inject eagerly to kick off automatic update check.
    _update = inject(UpdateService),
  ) {}

  private _setupPlatformStyles(platformStyle = inject(PlatformStyleService)) {
    const domRoot = this._doc.body.parentElement as HTMLHtmlElement;
    platformStyle.addBrowserClass(domRoot);
    platformStyle.addPlatformClass(domRoot);
  }

  private _setupPreconnectUrls(
    appConfig = inject(AppConfig),
    config = inject(ConfigService),
  ) {
    // Await settings to become available.
    // No need to unsubscripe, since it must be available for the app to start
    appConfig
      .init$()
      .pipe(first())
      .subscribe(() => {
        this._setPreconnectUrls(config.preconnectUrls);
      });
  }

  /**
   * Appends the given urls as preconnect hints.
   * @param preconnectUrls
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/preconnect
   */
  private _setPreconnectUrls(preconnectUrls: string[]) {
    for (const url of preconnectUrls) {
      const linkElement = this._doc.createElement('link');
      linkElement.rel = 'preconnect';
      linkElement.href = url;
      this._doc.head.appendChild(linkElement);
    }
  }
}
